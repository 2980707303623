<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12">
        <v-subheader class="text-h5 my-4">
          <v-divider class="mr-4"></v-divider>
          <v-icon color="primary" class="mr-2" left size="32">
            mdi-view-dashboard
          </v-icon>
          Panel de Control
          <span class="ml-2 text-subtitle-1 grey--text">{{ $store.state.Auth.token.claims.type }}</span>
          <v-divider class="ml-4"></v-divider>
        </v-subheader>
      </v-col>

      <!-- Main sections -->
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, i) in filteredAdminItems" :key="i" class="pa-2">
        <v-card class="mx-auto fill-height rounded-lg" :class="{ 'has-submenu': item.items }"
          @click="handleItemClick(item)" :elevation="2" hover>
          <v-card-title class="pt-6 pb-2 d-flex align-center">
            <v-avatar color="primary" size="42" class="mr-3 elevation-1">
              <v-icon dark size="24">{{ item.icon }}</v-icon>
            </v-avatar>
            <span class="text-h6">{{ item.title }}</span>
          </v-card-title>

          <!-- Show subitems if they exist -->
          <v-card-text v-if="item.items" class="pb-3 pt-2">
            <v-list class="pa-0">
              <v-list-item v-for="(subitem, j) in item.items" :key="j" @click.stop="showData(subitem.route)"
                class="px-3 rounded-lg mb-1 transition-swing" link>
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary" small>{{ subitem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">{{ subitem.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>

          <!-- Show description for items without subitems -->
          <v-card-text v-else class="text-body-2 pt-2 grey--text text--darken-1">
            {{ getItemDescription(item.title) }}
          </v-card-text>

          <v-card-actions v-if="!item.items" class="pt-0">
            <v-spacer></v-spacer>
            <v-btn text small color="primary" class="text-none" @click.stop="showData(item.route)">
              Acceder
              <v-icon small class="ml-1">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

      adminItems: [
        { title: 'Inicio', icon: 'mdi-view-dashboard-outline', route: '/inicio' },
        { title: 'Comunidad', icon: 'mdi-bullhorn-outline', route: '/comunidad' },

        { title: 'Usuarios', icon: 'mdi-account-group-outline', route: '/usuarios' },

        { title: 'Punto de Venta', icon: 'mdi-cash-register', route: '/punto-venta' },
        { title: 'Inventario & Tienda', icon: 'mdi-store-outline', route: '/inventario-tienda' },

        {
          title: 'Clases', icon: 'mdi-calendar-outline',
          items: [
            { title: 'Reservas', icon: 'mdi-calendar-clock', route: '/agenda/reservas' },
            { title: 'Configuración', icon: 'mdi-cog-outline', route: '/agenda/configuracion' },
            { title: 'Suscripciones', icon: 'mdi-account-cash', route: '/agenda/suscripciones' }
          ]
        },

        {
          title: 'Entrenamiento', icon: 'mdi-dumbbell',
          items: [
/*             { title: 'Actividad', icon: 'mdi-history', route: '/actividades' },
 */            { title: 'Programas', icon: 'mdi-clipboard-list-outline', route: '/programas' },
            { title: 'Glosario', icon: 'mdi-database-search-outline', route: '/glosario' },
          ]
        },

        {
          title: 'Evaluaciones', icon: 'mdi-clipboard-text-outline',
          items: [
            { title: 'Calendario', icon: 'mdi-calendar-month', route: '/evaluaciones' },/* 
            { title: 'Formularios', icon: 'mdi-file-document-edit-outline', route: '/formularios' },
            { title: 'Proformas', icon: 'mdi-human-male-height-variant', route: '/proformas' }, */

          ]
        },
        {
          title: 'Negocio',
          icon: 'mdi-handshake-outline',
          items: [
            { title: 'Tareas', icon: 'mdi-order-bool-ascending-variant', route: '/tareas' },

            { title: 'Notificaciones E-mail', icon: 'mdi-email-outline', route: '/negocio/email' },

            { title: 'Análisis', icon: 'mdi-chart-line', route: '/negocio/analisis' }
          ]
        },
        {
          title: 'Finanzas',
          icon: 'mdi-cash-multiple',
          items: [
            { title: 'Ingresos y Gastos', icon: 'mdi-cash-sync', route: '/finanzas/ingresos-gastos' },
            { title: 'Pagos de Suscripción', icon: 'mdi-cash-check', route: '/finanzas/pagos-subscripcion' },
            { title: 'Resumen Financiero', icon: 'mdi-cash-multiple', route: '/finanzas/resumen-financiero' }
          ]
        },


        { title: 'Control de Acceso', icon: 'mdi-qrcode-scan', route: '/negocio/control-acceso' },



      ],
      itemDescriptions: {
        'Inicio': 'Panel principal y resumen general',
        'Comunidad': 'Gestión de comunicaciones y anuncios',
        'Usuarios': 'Administración de usuarios y perfiles',
        'Punto de Venta': 'Sistema de ventas y facturación',
        'Control de Acceso': 'Gestión de entrada y códigos QR',
      }
    }
  },
  computed: {
    filteredAdminItems() {
      const userType = this.$store.state.Auth.token.claims.type;

      // Filter items based on user type
      return this.adminItems.filter(item => {
        if (userType === 'superuser') return true;
        if (userType === 'admin') {
          return !['Logs', 'Recursos'].includes(item.title);
        }
        if (userType === 'entrenador') {
          return ['Inicio', 'Comunidad', 'Entrenamiento', 'Control de Acceso'].includes(item.title);
        }
        return false;
      });
    }
  },
  methods: {
    showData(route) {
      this.$vuetify.goTo(0);
      this.$router.push(route);
    },
    handleItemClick(item) {
      if (item.route) {
        this.showData(item.route);
      }
      // If it has subitems, the card will expand to show them
    },
    getItemDescription(title) {
      return this.itemDescriptions[title] || title;
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  }
}
</script>

<style scoped>
.has-submenu {
  cursor: default;
}

.has-submenu .v-list-item {
  cursor: pointer;
  transition: all 0.2s ease;
}

.v-list-item:hover {
  background: rgba(var(--v-primary-base), 0.04);
  transform: translateX(4px);
}

.v-card {
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.v-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.v-avatar {
  transition: all 0.3s ease;
}

.v-card:hover .v-avatar {
  transform: scale(1.05);
}

.transition-swing {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* Gradient background variant - uncomment if you want to use it
.v-card {
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
}
*/
</style>
